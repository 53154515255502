const Default = ({ light, height }) => (
  <svg height={height ?? '32'} viewBox="0 0 161 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M160.416 0.492737V5.76779V15.8094V19.5115C160.416 21.863 160.285 23.9286 159.137 26.0577C158.121 27.9484 156.825 29.2513 155.12 30.2364C153.037 31.4439 150.627 32 148.217 32C144.986 32 142.051 31.3168 140.051 30.2364V23.9921C142.133 25.5492 145.38 26.1053 147.298 26.1053C148.971 26.1053 150.791 25.6763 152.086 24.6277C153.595 23.4042 153.464 21.863 153.464 20.1312C152.168 22.4509 150.004 23.0547 147.38 23.0547C142.002 23.0547 138.411 19.305 138.411 13.2037V0.492737H145.38V12.2822C145.38 15.0786 147.118 16.9375 149.381 16.9375C151.644 16.9375 153.431 15.1262 153.464 12.3775V0.492737H160.416Z"
      fill={light ? 'white' : 'black'}
    />
    <path
      d="M108.748 11.5352C108.748 18.0813 104.616 23.0704 97.3847 23.0704C94.3839 23.0704 91.9571 22.0694 90.8913 20.1787V31.0783H83.9224L83.9716 11.5352C83.9552 4.95728 89.4319 0 96.3516 0C103.599 0 108.748 4.95728 108.748 11.5352ZM101.287 11.5352C101.287 8.4369 99.1556 6.45081 96.3516 6.45081C93.5477 6.45081 91.416 8.4369 91.416 11.5352C91.416 14.6335 93.5477 16.5719 96.3516 16.5719C99.1556 16.5719 101.287 14.6335 101.287 11.5352Z"
      fill={light ? 'white' : 'black'}
    />
    <path
      d="M22.3497 10.3281V22.5624H15.4136V10.5982C15.4136 7.88122 13.7739 6.11758 11.1995 6.11758C8.62506 6.11758 6.98532 7.88122 6.98532 10.5982V22.5624H0V10.3281C0 4.08382 4.39452 0.000427246 11.1995 0.000427246C17.9552 0.000427246 22.3497 4.08382 22.3497 10.3281Z"
      fill={light ? 'white' : 'black'}
    />
    <path
      d="M84.4305 0.524353L75.6742 22.562H70.6238L65.5898 10.3277L60.523 22.562H55.4726L46.7163 0.524353H53.9968L58.1289 13.2989L62.9334 0.524353H68.2626L73.067 13.2671L77.15 0.524353H84.4305Z"
      fill={light ? 'white' : 'black'}
    />
    <path
      d="M46.7654 12.9175H31.8109C31.6798 15.5551 34.7133 17.3664 38.4355 17.1757C40.8132 17.0486 42.9448 16.556 45.2241 15.0784V21.3227C43.3876 22.2601 41.0427 23.0863 37.0909 23.0863C29.7121 23.0863 24.8256 18.4786 24.8256 11.5511C24.8256 5.13206 29.5317 0.0159022 36.4678 0.0159022C44.0107 1.34595e-05 47.9297 5.71995 46.7654 12.9175ZM40.9279 8.56402C40.7968 6.45083 38.5831 5.03673 36.353 5.03673C34.2214 5.03673 32.1225 6.54616 31.8437 8.56402H40.9279Z"
      fill={light ? 'white' : 'black'}
    />
    <path
      d="M123.129 0C130.065 0 135.525 4.95727 135.525 11.5352L135.574 22.562H128.605V20.1786C127.54 22.0694 125.113 23.0704 122.112 23.0704C114.864 23.0704 110.749 18.0813 110.749 11.5352C110.749 4.95727 115.897 0 123.129 0ZM123.129 16.5719C125.933 16.5719 128.064 14.6335 128.064 11.5352C128.064 8.4369 125.933 6.45081 123.129 6.45081C120.325 6.45081 118.193 8.4369 118.193 11.5352C118.209 14.6335 120.341 16.5719 123.129 16.5719Z"
      fill={light ? 'white' : 'black'}
    />
  </svg>
);

export default Default;
