import { jsx } from '@emotion/react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

SelectLink.propTypes = {
  children: PropTypes.any,
  to: PropTypes.string,
  linkStyle: PropTypes.any,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
};

export default function SelectLink({
  children,
  to = '',
  linkStyle,
  ariaLabel,
  disabled,
  handleClick,
}) {
  const internalLink = to.startsWith('/');

  const blurAndHandleClick = () => {
    if (handleClick) handleClick();
    document.activeElement.blur();
  };

  return internalLink ? (
    <Link
      aria-label={ariaLabel}
      css={linkStyle}
      to={to}
      onClick={blurAndHandleClick}
      disabled={disabled}
    >
      {children}
    </Link>
  ) : (
    <a
      aria-label={`${ariaLabel}, opens in a new tab`}
      css={linkStyle}
      href={to}
      target="_blank"
      rel="noreferrer"
      disabled={disabled}
      onClick={blurAndHandleClick}
    >
      {children}
    </a>
  );
}
