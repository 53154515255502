import { useEffect, useState } from 'react';
import { trackEvent } from '@utils/appinsights-service';

import Logo from '@components/common/Logo/Logo';
import SelectLink from '@components/common/SelectLink/SelectLink';
import * as style from './Footer.style';

const Footer = () => {

  const [ links, setLinks ] = useState([]);

  useEffect(() => {
    const environment = !!process.env.GATSBY_DEKO_ENV ? process.env.GATSBY_DEKO_ENV : '#{GATSBY_DEKO_ENV}';
    const isStaging = environment === 'staging';
    const continueApplicationLink = {
      path: '/retrieve-your-application',
      label: 'Retrieve my application',
      id: 'retrieve-your-application'
    };
    const defaultLinks = [
      { path: '/about-us', label: 'About', id: 'about-us' },
      { path: '/payment-options', label: 'Payment options', id: 'payment-options' },
      { path: '/privacy-notice', label: 'Privacy notice', id: 'privacy-notice' },
      { path: '/cookie-notice', label: 'Cookie notice', id: 'cookie-notice' },
    ];

    if (isStaging) {
      setLinks([...defaultLinks, continueApplicationLink]);
    } else {
      setLinks(defaultLinks);
    }
  }, []);

  const year = new Date().getFullYear();

  return (
    <footer data-cy="footer" css={style.footerWrapper}>
      {/* Links */}
      <div data-cy="footer-links" css={style.linksWrapper} className="container">
        {links.map(({ path, label, id }) => (
          path && <SelectLink
            key={id}
            data-cy="footer-link"
            to={path}
            linkStyle={style.link}
            className="col-12 col-lg-auto"
            onClick={() => trackEvent && trackEvent(`${label}-footer click`)}
            ariaLabel={`${label} - click to navigate to ${path}`}
          >
            {label}
          </SelectLink>
        ))}
      </div>
      <section css={style.legalWrapper} className="container">
        <h5 css={style.legalTitle}>
          Credit available only to UK residents aged 18 and over. Subject to status. Terms apply.
        </h5>
        <p>
          NewDay Ltd and NewDay Cards Ltd are companies registered in England and Wales with
          registered numbers 7297722 and 4134880 respectively. They form part of the NewDay group of
          companies. The registered office for these companies is 7 Handyside Street, London, N1C
          4DA. NewDay Ltd and NewDay Cards Ltd are authorised and regulated by the Financial Conduct
          Authority (FCA) with numbers 690292 and 682417 respectively. NewDay Ltd is also authorised
          by the FCA under the Payment Services Regulations 2017 (ref no: 555318) for the provision
          of payment services.
        </p>

        <div css={style.legalFooter}>
          <p>{`© ${year} Newpay. All rights reserved.`}</p>

          <SelectLink
            to="/"
            ariaLabel="NewPay Logo - click to navigate back to main page"
            linkStyle={style.logo}
            handleClick={() => {
              trackEvent && trackEvent('Logo - click');
            }}
          >
            <Logo light />
          </SelectLink>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
