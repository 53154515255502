export default {
  teal900: '#038766',
  teal800: '#049e77',
  teal700: '#04b488',
  teal600: '#05cb99',
  teal500: '#05e1aa',
  teal400: '#37e7bb',
  teal300: '#69edcc',
  teal200: '#9bf3dd',
  teal100: '#cdf9ee',
  teal50: '#e6fcf7',

  grey900: '#283237',
  grey800: '#3d474b',
  grey700: '#535b5f',
  grey600: '#697073',
  grey500: '#7e8487',
  grey400: '#94999b',
  grey300: '#a9adaf',
  grey200: '#bfc2c3',
  grey100: '#d4d6d7',
  grey50: '#eaebf7',
  grey40: '#eaebeb',

  // secondary colors

  blue900: '#1b7290',
  blue800: '#1f85a8',
  blue700: '#2498c0',
  blue600: '#29abd8',
  blue500: '#2dbef0',
  blue400: '#57cbf3',
  blue300: '#81d8f6',
  blue200: '#abe5f9',
  blue100: '#d5f2fc',
  blue50: '#eaf9fe',
  blue40: '#eafbf7',

  red900: '#902727',
  red800: '#a82e2e',
  red700: '#c03434',
  red600: '#d83b3b',
  red500: '#f04141',
  red400: '#f36767',
  red300: '#f68d8d',
  red200: '#f9b3b3',
  red100: '#fcd9d9',
  red50: '#feecec',

  lilac900: '#485499',
  lilac800: '#5462b3',
  lilac700: '#6070cc',
  lilac600: '#6c7ee6',
  lilac500: '#788cff',
  lilac400: '#93a3ff',
  lilac300: '#aebaff',
  lilac200: '#c9d1ff',
  lilac100: '#e4e8ff',
  lilac50: '#f2f4ff',

  pink900: '#f2f4ff',
  pink800: '#ac5858',
  pink700: '#c46464',
  pink600: '#dd7171',
  pink500: '#f57d7d',
  pink400: '#f79797',
  pink300: '#f9b1b1',
  pink200: '#fbcbcb',
  pink100: '#fde5e5',
  pink50: '#fef2f2',

  yellow900: '#997800',
  yellow800: '#b38c00',
  yellow700: '#cca000',
  yellow600: '#e6b400',
  yellow500: '#ffc800',
  yellow400: '#ffd333',
  yellow300: '#ffde66',
  yellow200: '#ffe999',
  yellow100: '#fff4cc',
  yellow50: '#fffae6',

  white: '#ffffff',
  black: '#000000',

  webkitFocusBlue: '#1366cf',

  largeFontSize: '2.2rem',
  mediumFontSize: '1.4rem',
  smallFontSize: '1.1rem',
  extraSmallFontSize: '0.9rem',

  mobile: '576px',
  tablet: '767px',
  laptop: '992px',
  desktop: '1200px',
  fullHD: '1440px',
};
